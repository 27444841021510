import React from 'react';
import { NotionRenderer } from "react-notion";

import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";

export const toNotionImageUrl: MapImageUrl = (url, block) => {
  let notionUrl = url

  if (!url.startsWith('https://www.notion.so')) {
    notionUrl = 'https://www.notion.so'.concat(
      url.startsWith('/image') ? url : `/image/${encodeURIComponent(url)}`,
    )
  }

  const imageUrl = new URL(notionUrl)

  if (block) {
     // Changed here, to switch from `table=team` to `table=block` in the url
    const table = ['space', 'team'].includes(block.value.parent_table)
      ? 'block'
      : block.value.parent_table
    imageUrl.searchParams.set('table', table)
    imageUrl.searchParams.set('id', block.value.id)
    imageUrl.searchParams.set('cache', 'v2')
  }

  return imageUrl.toString()
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blockMap: {}
    }
  }

  async componentDidMount() {
    const blogData = await fetch(
      "https://notion-api.splitbee.io/v1/page/041db4e9b36e41ac93f8b12ada576964"
    ).then(res => res.json());

    this.setState({ blockMap: blogData })
  }

  render() {
    return (
      <div>
        <NotionRenderer fullPage blockMap={this.state.blockMap} mapImageUrl={toNotionImageUrl} />
      </div>
    )
  }
}

export default App;

/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
